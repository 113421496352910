<!-- eslint-disable max-len -->
<template>
  <div class="flex flex-col min-h-full">
    <div class="flex items-center justify-between gap-4 bg-white border-b py-1 lg:py-2 px-6">
      <h3 class="text-gray-500 text-lg">
        Novo link de pagamento
      </h3>
      <Button
        aria-label="Close"
        kind="plain"
        class="-mr-3"
        @click="showModal()"
      >
        <XIcon size="24"/>
        <span class="sr-only">
          Fechar
        </span>
      </Button>
    </div>
    <div class="flex-grow bg-white lg:bg-gray-100 w-full">
      <div class="max-w-lg mx-auto bg-white p-6 pb-9 lg:border lg:rounded-xl lg:my-6">
        <header class="mb-6">
          <div class="flex justify-between">
            <Badge kind="primary">Etapa opcional</Badge>
            <v-popover
              placement='top'
              :offset="8"
              class="inline"
            >
              <QuestionMarkCircleIcon size="24" class="text-gray-500 cursor-pointer" />
              <p slot="popover" class="w-80 leading-4">
                A pré-análise de crédito é uma verificação prévia de crédito, que informa se o CPF pesquisado está ou não elegível a realizar uma compra com a Parcelex.
                <br />
                <br />
                A pré-aprovação de crédito não garante a confirmação do pedido, pois ainda existe a possibilidade do cliente ser negado após o envio da documentação.
              </p>
            </v-popover>
          </div>
          <h1 class="flex-grow font-bold text-xl leading-9">
            Pré-análise de crédito
          </h1>
          <p class="sub-message text-gray-500 mt-3">
            <span class="sub-message text-gray-700">Importante: </span>
            Certifique-se de que os dados preenchidos pertencem ao CPF aplicado.
          </p>
        </header>
        <form class="space-y-5">
          <div>
            <label class="form-label">CPF</label>
              <Input
                type="text"
                v-model="paymentLinkUser.cpf"
                @input="handleFormInput('cpf', ...arguments)"
                v-mask="'###.###.###-##'"
                required
                :errorMessage="getMessage('cpf')"
              />
          </div>
          <div>
            <label class="form-label">Celular</label>
              <Input
                type="text"
                v-model="paymentLinkUser.phone"
                @input="handleFormInput('phone', ...arguments)"
                v-mask="'(##) #####-####'"
                required
                :errorMessage="getMessage('phone')"
              />
          </div>
          <div>
            <label class="form-label">Email</label>
              <Input
                type="text"
                v-model="paymentLinkUser.email"
                @input="handleFormInput('email', ...arguments)"
                required
                :errorMessage="getMessage('email')"
              />
          </div>

          <div>
            <label class="form-label">CEP</label>
              <Input
                type="text"
                v-model="paymentLinkUser.zipCode"
                @input="handleFormInput('zipCode', ...arguments)"
                v-mask="'#####-###'"
                required
                :errorMessage="getMessage('zipCode')"
              />
          </div>
        </form>
      </div>
    </div>
    <nav class="py-4 px-6 border-t bg-white lg:sticky lg:bottom-0 lg:z-10">
      <div class="flex gap-4">
        <div class="flex flex-grow gap-4 justify-end">
          <Button v-if="canShowCPFFowardButton" link kind="default" @click="jumpStep">
            <span>Pular etapa</span>
          </Button>
          <Button link kind="primary" @click="applyPreApprovedCreditForPaymentLink" class="w-full lg:w-auto">
            <span>Avançar</span>
          </Button>
        </div>
      </div>
    </nav>
    <CancelCreatePaymentLinkModal
      :showModal="openModal"
      @onCloseModal="showModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import { XIcon, QuestionMarkCircleIcon } from '@vue-hero-icons/outline';
import stringMixin from '../../../mixins/string';
import utilMixin from '../../../mixins/util';
import CancelCreatePaymentLinkModal from '../components/CancelCreatePaymentLinkModal.vue';
import { Button, Input, Badge } from '../../../components';

export default {
  name: 'credit-check',
  metaInfo: {
    title: 'Novo link de pagamentos',
  },
  components: {
    XIcon,
    CancelCreatePaymentLinkModal,
    QuestionMarkCircleIcon,
    Button,
    Input,
    Badge,
  },
  directives: {
    mask,
  },
  mixins: [stringMixin, utilMixin],
  data: () => ({
    openModal: false,
    openDropdown: false,
    formInputs: {
      phone: {
        isValid: false,
        validateFunction: 'isValidPhone',
        errorMessage: 'Insira um celular válido',
        currentMessage: '',
      },
      cpf: {
        isValid: false,
        validateFunction: 'isValidCpf',
        errorMessage: 'CPF inválido',
        currentMessage: '',
      },
      email: {
        isValid: false,
        validateFunction: 'isValidEmail',
        errorMessage: 'Email inválido',
        currentMessage: '',
      },
      zipCode: {
        validateFunction: 'isValidZipCode',
        isValid: false,
        errorMessage: 'CEP inválido',
        currentMessage: '',
      },
    },
  }),
  computed: {
    ...mapState('retailer', ['currentRetailer']),
    ...mapState('paymentLink', ['paymentLinkUser', 'verifyPreApprovedCreditResult']),
    isValidForm() {
      let isValid = true;
      const keys = Object.keys(this.formInputs);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (!this.formInputs[key].isValid) {
          isValid = false;
          break;
        }
      }
      return isValid;
    },
    canShowCPFFowardButton() {
      return this.currentRetailer.type !== 'imobiliaria';
    },
  },
  mounted() {
    this.handleFormInput('email', '');
    this.handleFormInput('phone', '');
    this.handleFormInput('zipCode', '');
  },
  methods: {
    ...mapActions('paymentLink', ['setPaymentLinkUserData', 'createPreApprovedPaymentLink']),
    ...mapActions('loading', ['setIsLoading']),
    ...mapActions('toast', ['showToast']),
    showModal() {
      this.openModal = !this.openModal;
    },
    showDrawer() {
      this.openDrawer = !this.openDrawer;
    },
    showDropdown() {
      this.openDropdown = !this.openDropdown;
    },
    async goToPaymentLinkMenu() {
      await this.$router.replace({ name: 'payment-link-list' });
    },
    async applyPreApprovedCreditForPaymentLink() {
      this.handleFormInput('cpf', this.paymentLinkUser.cpf);
      this.handleFormInput('email', this.paymentLinkUser.email);
      this.handleFormInput('phone', this.paymentLinkUser.phone);
      this.handleFormInput('zipCode', this.paymentLinkUser.zipCode);
      if (!this.isValidForm) {
        this.handleErrorMessages();
        return;
      }

      this.setIsLoading({ isLoading: true, loadingMessage: 'Verificando crédito' });

      try {
        const {
          inputError,
          pendingPayment,
          message,
        } = await this.createPreApprovedPaymentLink();

        if (pendingPayment) {
          this.setIsLoading({ isLoading: false });
          await this.$router.replace({
            name: 'link-already-associated-to-cpf',
          });
          return;
        }

        if (inputError?.maxDailyTries) {
          this.setIsLoading({ isLoading: false });
          await this.$router.replace({
            name: 'payment-link-number-of-link-exceeded',
          });
          return;
        }

        if (inputError) {
          this.formInputs[inputError].isValid = false;
          this.handleErrorMessages();
          this.setIsLoading({ isLoading: false });
          return;
        }

        if (['PRE_APPROVED', 'APPROVED'].includes(this.verifyPreApprovedCreditResult)) {
          this.setIsLoading({ isLoading: false, showIcon: 'success', showIconDelay: 1500 });
          await this.$router.replace({
            name: 'create-payment-link',
          });
        } else if (['DENIED'].includes(this.verifyPreApprovedCreditResult)) {
          this.setIsLoading({ isLoading: false });
          await this.$router.replace({
            name: 'denied-payment-link',
          });
        } else {
          const errorMessage = message ? `${message}. Contate o suporte.` : 'Contate o suporte.';
          this.showToast({
            toastMessage: `Erro inesperado: ${errorMessage}`,
            toastType: 'danger',
            hideToastDelay: 5000,
          });
        }
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.message || error.response.data;
          if (errorMessage === 'Daily limit exceed, contact comercial@parcelex.com.br to know about your limits') {
            this.showToast({
              toastMessage: 'Limite de aplicações diárias atingido, contate o suporte com a mensagem.',
              toastType: 'danger',
              hideToastDelay: 5000,
            });
          } else {
            this.showToast({
              toastMessage: `Erro inesperado: ${errorMessage}. Contate o suporte com a mensagem`,
              toastType: 'danger',
              hideToastDelay: 5000,
            });
          }
        }
      }

      this.setIsLoading({ isLoading: false });
    },
    async jumpStep() {
      await this.$router.replace({ name: 'create-payment-link' });
    },
    handleFormInput(field, value) {
      this.setPaymentLinkUserData({ field, value });
      const { validateFunction } = this.formInputs[field];
      this.formInputs[field].isValid = this[validateFunction](value);
      if (this.formInputs[field].currentMessage !== '') {
        this.formInputs[field].currentMessage = '';
      }
    },
    handleErrorMessages() {
      Object.keys(this.formInputs).forEach((key) => {
        if (!this.formInputs[key].isValid) {
          this.formInputs[key].currentMessage = this.formInputs[key].errorMessage;
        }
      });
    },
    getMessage(field) {
      return this.formInputs[field].currentMessage;
    },
  },
};
</script>

<style scoped>
.sub-message {
  font-size: 14px;
  line-height: 20px;
}
</style>
